import { Component, EventEmitter, Injector, Output, ViewChild, ElementRef, OnInit } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { IAjaxResponse } from '@abp/abpHttpInterceptor';
import { TokenService } from '@abp/auth/token.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import {
    CurrentUserProfileEditDto,
    ChangePasswordInput,
    PasswordComplexitySetting,
    SettingScopes,
    ProfileServiceProxy,
    UpdateProfilePictureInput
} from '@shared/service-proxies/service-proxies';
import { FileUploader, FileUploaderOptions, FileItem } from 'ng2-file-upload';
import { ModalDirective } from 'ngx-bootstrap';
import { finalize } from 'rxjs/operators';
import { County } from '@account/register/county';


@Component({
    selector: 'myProfileModal',
    templateUrl: './my-profile-modal.component.html'
})
export class MyProfileModalComponent extends AppComponentBase implements OnInit {

    @ViewChild('myProfileModal', {static: true}) modal: ModalDirective;
    @ViewChild('currentPasswordInput', {static: true}) currentPasswordInput: ElementRef;
    
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();

    public active = false;
    public saving = false;
    public uploader: FileUploader;
    public temporaryPictureUrl: string;
    profilePicture = AppConsts.appBaseUrl + '/assets/common/images/default-profile-picture.png';
    uploadAwatar = AppConsts.appBaseUrl + '/assets/common/images/edit-icon-img.jpg';
    public user: CurrentUserProfileEditDto;
    public showTimezoneSelection: boolean = abp.clock.provider.supportsMultipleTimezone;
    public canChangeUserName: boolean;
    public defaultTimezoneScope: SettingScopes = SettingScopes.User;
    private _initialTimezone: string = undefined;
    public savedPhoneNumber: string;
    public newPhoneNumber: string;
    isMultiTenancyEnabled: boolean = this.multiTenancy.isEnabled;
    isTwoFactorLoginEnabledForApplication = false;
    counties = new County().counties;
    passwordComplexitySetting: PasswordComplexitySetting = new PasswordComplexitySetting();
    currentPassword: string;
    password: string;
    passwordChanged: boolean = false;
    passwordConfirm: string;
    avatarActive:boolean = false;
    profileActive:boolean = true;

    private maxProfilPictureBytesUserFriendlyValue = 5;
    private temporaryPictureFileName: string;
    private _uploaderOptions: FileUploaderOptions = {};

    imageChangedEvent: any = '';

    constructor(
        injector: Injector,
        private _profileService: ProfileServiceProxy,
        private _tokenService: TokenService
    ) {
        super(injector);        
    }

    ngOnInit(): void {
        this.getProfilePicture();
        this.initFileUploader();
        this.isTwoFactorLoginEnabledForApplication =
            abp.setting.getBoolean('Abp.Zero.UserManagement.TwoFactorLogin.IsEnabled');
    }

    show(): void {
        this.active = true;        
        this.password = null;
        this.passwordConfirm = null;
        this._profileService.getCurrentUserProfileForEdit().subscribe((result) => {
            
            this.user = result;
            this._initialTimezone = result.timezone;
            this.canChangeUserName = this.user.userName !== AppConsts.userManagement.defaultAdminUserName;
            this.modal.show();
            this.savedPhoneNumber = result.phoneNumber;
        });
    }

    onShown(): void {
        document.getElementById('Name').focus();
    }

    close(): void {
        this.active = false;
        this.modal.hide();
    }

    save(): void {
        this.saving = true;
        this.user.password = this.passwordChanged ? this.password : null;        
        this._profileService.updateCurrentUserProfile(this.user)
            .pipe(finalize(() => { this.saving = false; }))
            .subscribe(() => {
                this.appSession.user.name = this.user.name;
                this.appSession.user.surname = this.user.surname;                
                this.appSession.user.userName = this.user.userName;
                this.appSession.user.emailAddress = this.user.emailAddress;
                this.appSession.user.county = this.user.county;
                if (this.passwordChanged)
                {
                    this.appSession.user.passwd = this.password;
                }                    
                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(null);

                if (abp.clock.provider.supportsMultipleTimezone && this._initialTimezone !== this.user.timezone) {
                    this.message.info(this.l('TimeZoneSettingChangedRefreshPageNotification')).then(() => {
                        window.location.reload();
                    });
                }
            });
    }

    passwordHasChanged(){
        this.passwordChanged = true        
    }

    getProfilePicture(): void {
        this._profileService.getProfilePicture().subscribe(result => {
            if (result && result.profilePicture) {
                this.profilePicture = 'data:image/jpeg;base64,' + result.profilePicture;
            }
        });
    }

    showAvatarTab(){
        this.active = false;     
        let element: HTMLElement = document.getElementById('inputTypeFile') as HTMLElement;
        element.click();
    }

    showProfileTab(){
        this.active = true;        
    }

    fileChangeEvent(event: any): void {
        if (event.target.files[0].size > 5242880) { //5MB
            this.message.warn(this.l('ProfilePicture_Warn_SizeLimit', this.maxProfilPictureBytesUserFriendlyValue));
            return;
        }

        this.imageChangedEvent = event;
    }

    imageCroppedFile(file: File) {
        let files: File[] = [file];
        this.uploader.clearQueue();
        this.uploader.addToQueue(files);
    }
    
    initFileUploader(): void {
        this.uploader = new FileUploader({ url: AppConsts.remoteServiceBaseUrl + '/Profile/UploadProfilePicture' });
        this._uploaderOptions.autoUpload = false;
        this._uploaderOptions.authToken = 'Bearer ' + this._tokenService.getToken();
        this._uploaderOptions.removeAfterUpload = true;
        this.uploader.onAfterAddingFile = (file) => {
            file.withCredentials = false;
        };

        this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {
            form.append('FileType', fileItem.file.type);
            form.append('FileName', 'ProfilePicture');
            form.append('FileToken', this.guid());
        };

        this.uploader.onSuccessItem = (item, response, status) => {
            const resp = <IAjaxResponse>JSON.parse(response);
            if (resp.success) {
                this.updateProfilePicture(resp.result.fileToken);
            } else {
                this.message.error(resp.error.message);
            }
        };

        this.uploader.setOptions(this._uploaderOptions);
    }

    updateProfilePicture(fileToken: string): void {
        const input = new UpdateProfilePictureInput();
        input.fileToken = fileToken;
        input.x = 0;
        input.y = 0;
        input.width = 0;
        input.height = 0;

        this.saving = true;
        this._profileService.updateProfilePicture(input)
            .pipe(finalize(() => { this.saving = false; }))
            .subscribe(() => {
                abp.event.trigger('profilePictureChanged');
                this.getProfilePicture();
            });
    }

    guid(): string {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    saveAvatar(): void {
        this.active = true;
        this.uploader.uploadAll();
    }
}
