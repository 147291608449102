export class County {
    public counties = [
        {
            "countyName": "Aleksandrowski"
        },
        {
            "countyName": "Augustowski"
        },
        {
            "countyName": "Bartoszycki"
        },
        {
            "countyName": "Bełchatowski"
        },
        {
            "countyName": "Będziński"
        },
        {
            "countyName": "Bialski"
        },
        {
            "countyName": "Biała Podlaska"
        },
        {
            "countyName": "Białobrzeski"
        },
        {
            "countyName": "Białogardzki"
        },
        {
            "countyName": "Białostocki"
        },
        {
            "countyName": "Białystok"
        },
        {
            "countyName": "Bielski"
        },
        {
            "countyName": "Bielsko-Biała"
        },
        {
            "countyName": "Bieruńsko-Lędziński"
        },
        {
            "countyName": "Bieszczadzki"
        },
        {
            "countyName": "Biłgorajski"
        },
        {
            "countyName": "Bocheński"
        },
        {
            "countyName": "Bolesławiecki"
        },
        {
            "countyName": "Braniewski"
        },
        {
            "countyName": "Brodnicki"
        },
        {
            "countyName": "Brzeski"
        },
        {
            "countyName": "Brzeziński"
        },
        {
            "countyName": "Brzozowski"
        },
        {
            "countyName": "Buski"
        },
        {
            "countyName": "Bydgoski"
        },
        {
            "countyName": "Bydgoszcz"
        },
        {
            "countyName": "Bytom"
        },
        {
            "countyName": "Bytowski"
        },
        {
            "countyName": "Chełm"
        },
        {
            "countyName": "Chełmiński"
        },
        {
            "countyName": "Chełmski"
        },
        {
            "countyName": "Chodzieski"
        },
        {
            "countyName": "Chojnicki"
        },
        {
            "countyName": "Chorzów"
        },
        {
            "countyName": "Choszczeński"
        },
        {
            "countyName": "Chrzanowski"
        },
        {
            "countyName": "Ciechanowski"
        },
        {
            "countyName": "Cieszyński"
        },
        {
            "countyName": "Czarnkowsko-Trzcianecki"
        },
        {
            "countyName": "Częstochowa"
        },
        {
            "countyName": "Częstochowski"
        },
        {
            "countyName": "Człuchowski"
        },
        {
            "countyName": "Dąbrowa Górnicza"
        },
        {
            "countyName": "Dąbrowski"
        },
        {
            "countyName": "Dębicki"
        },
        {
            "countyName": "Drawski"
        },
        {
            "countyName": "Działdowski"
        },
        {
            "countyName": "Dzierżoniowski"
        },
        {
            "countyName": "Elbląg"
        },
        {
            "countyName": "Elbląski"
        },
        {
            "countyName": "Ełcki"
        },
        {
            "countyName": "Garwoliński"
        },
        {
            "countyName": "Gdańsk"
        },
        {
            "countyName": "Gdański"
        },
        {
            "countyName": "Gdynia"
        },
        {
            "countyName": "Giżycki"
        },
        {
            "countyName": "Gliwice"
        },
        {
            "countyName": "Gliwicki"
        },
        {
            "countyName": "Głogowski"
        },
        {
            "countyName": "Głubczycki"
        },
        {
            "countyName": "Gnieźnieński"
        },
        {
            "countyName": "Goleniowski"
        },
        {
            "countyName": "Golubsko-Dobrzyński"
        },
        {
            "countyName": "Gołdapski"
        },
        {
            "countyName": "Gorlicki"
        },
        {
            "countyName": "Gorzowski"
        },
        {
            "countyName": "Gorzów Wielkopolski"
        },
        {
            "countyName": "Gostyniński"
        },
        {
            "countyName": "Gostyński"
        },
        {
            "countyName": "Górowski"
        },
        {
            "countyName": "Grajewski"
        },
        {
            "countyName": "Grodziski"
        },
        {
            "countyName": "Grodziski"
        },
        {
            "countyName": "Grójecki"
        },
        {
            "countyName": "Grudziądz"
        },
        {
            "countyName": "Grudziądzki"
        },
        {
            "countyName": "Gryficki"
        },
        {
            "countyName": "Gryfiński"
        },
        {
            "countyName": "Hajnowski"
        },
        {
            "countyName": "Hrubieszowski"
        },
        {
            "countyName": "Iławski"
        },
        {
            "countyName": "Inowrocławski"
        },
        {
            "countyName": "Janowski"
        },
        {
            "countyName": "Jarociński"
        },
        {
            "countyName": "Jarosławski"
        },
        {
            "countyName": "Jasielski"
        },
        {
            "countyName": "Jastrzębie-Zdrój"
        },
        {
            "countyName": "Jaworski"
        },
        {
            "countyName": "Jaworzno"
        },
        {
            "countyName": "Jelenia Góra"
        },
        {
            "countyName": "Jeleniogórski"
        },
        {
            "countyName": "Jędrzejowski"
        },
        {
            "countyName": "Kaliski"
        },
        {
            "countyName": "Kalisz"
        },
        {
            "countyName": "Kamiennogórski"
        },
        {
            "countyName": "Kamieński"
        },
        {
            "countyName": "Kartuski"
        },
        {
            "countyName": "Katowice"
        },
        {
            "countyName": "Kazimierski"
        },
        {
            "countyName": "Kędzierzyńsko-Kozielski"
        },
        {
            "countyName": "Kępiński"
        },
        {
            "countyName": "Kętrzyński"
        },
        {
            "countyName": "Kielce"
        },
        {
            "countyName": "Kielecki"
        },
        {
            "countyName": "Kluczborski"
        },
        {
            "countyName": "Kłobucki"
        },
        {
            "countyName": "Kłodzki"
        },
        {
            "countyName": "Kolbuszowski"
        },
        {
            "countyName": "Kolneński"
        },
        {
            "countyName": "Kolski"
        },
        {
            "countyName": "Kołobrzeski"
        },
        {
            "countyName": "Konecki"
        },
        {
            "countyName": "Konin"
        },
        {
            "countyName": "Koniński"
        },
        {
            "countyName": "Koszalin"
        },
        {
            "countyName": "Koszaliński"
        },
        {
            "countyName": "Kościański"
        },
        {
            "countyName": "Kościerski"
        },
        {
            "countyName": "Kozienicki"
        },
        {
            "countyName": "Krakowski"
        },
        {
            "countyName": "Kraków"
        },
        {
            "countyName": "Krapkowicki"
        },
        {
            "countyName": "Krasnostawski"
        },
        {
            "countyName": "Kraśnicki"
        },
        {
            "countyName": "Krosno"
        },
        {
            "countyName": "Krośnieński"
        },
        {
            "countyName": "Krośnieński"
        },
        {
            "countyName": "Krotoszyński"
        },
        {
            "countyName": "Kutnowski"
        },
        {
            "countyName": "Kwidzyński"
        },
        {
            "countyName": "Legionowski"
        },
        {
            "countyName": "Legnica"
        },
        {
            "countyName": "Legnicki"
        },
        {
            "countyName": "Leski"
        },
        {
            "countyName": "Leszczyński"
        },
        {
            "countyName": "Leszno"
        },
        {
            "countyName": "Leżajski"
        },
        {
            "countyName": "Lęborski"
        },
        {
            "countyName": "Lidzbarski"
        },
        {
            "countyName": "Limanowski"
        },
        {
            "countyName": "Lipnowski"
        },
        {
            "countyName": "Lipski"
        },
        {
            "countyName": "Lubaczowski"
        },
        {
            "countyName": "Lubański"
        },
        {
            "countyName": "Lubartowski"
        },
        {
            "countyName": "Lubelski"
        },
        {
            "countyName": "Lubiński"
        },
        {
            "countyName": "Lublin"
        },
        {
            "countyName": "Lubliniecki"
        },
        {
            "countyName": "Lwówecki"
        },
        {
            "countyName": "Łańcucki"
        },
        {
            "countyName": "Łaski"
        },
        {
            "countyName": "Łęczycki"
        },
        {
            "countyName": "Łęczyński"
        },
        {
            "countyName": "Łobeski"
        },
        {
            "countyName": "Łomża"
        },
        {
            "countyName": "Łomżyński"
        },
        {
            "countyName": "Łosicki"
        },
        {
            "countyName": "Łowicki"
        },
        {
            "countyName": "Łódzki Wschodni"
        },
        {
            "countyName": "Łódź"
        },
        {
            "countyName": "Łukowski"
        },
        {
            "countyName": "Makowski"
        },
        {
            "countyName": "Malborski"
        },
        {
            "countyName": "Miechowski"
        },
        {
            "countyName": "Mielecki"
        },
        {
            "countyName": "Międzychodzki"
        },
        {
            "countyName": "Międzyrzecki"
        },
        {
            "countyName": "Mikołowski"
        },
        {
            "countyName": "Milicki"
        },
        {
            "countyName": "Miński"
        },
        {
            "countyName": "Mławski"
        },
        {
            "countyName": "Mogileński"
        },
        {
            "countyName": "Moniecki"
        },
        {
            "countyName": "Mrągowski"
        },
        {
            "countyName": "Mysłowice"
        },
        {
            "countyName": "Myszkowski"
        },
        {
            "countyName": "Myślenicki"
        },
        {
            "countyName": "Myśliborski"
        },
        {
            "countyName": "Nakielski"
        },
        {
            "countyName": "Namysłowski"
        },
        {
            "countyName": "Nidzicki"
        },
        {
            "countyName": "Niżański"
        },
        {
            "countyName": "Nowodworski"
        },
        {
            "countyName": "Nowodworski"
        },
        {
            "countyName": "Nowomiejski"
        },
        {
            "countyName": "Nowosądecki"
        },
        {
            "countyName": "Nowosolski"
        },
        {
            "countyName": "Nowotarski"
        },
        {
            "countyName": "Nowotomyski"
        },
        {
            "countyName": "Nowy Sącz"
        },
        {
            "countyName": "Nyski"
        },
        {
            "countyName": "Obornicki"
        },
        {
            "countyName": "Olecki"
        },
        {
            "countyName": "Oleski"
        },
        {
            "countyName": "Oleśnicki"
        },
        {
            "countyName": "Olkuski"
        },
        {
            "countyName": "Olsztyn"
        },
        {
            "countyName": "Olsztyński"
        },
        {
            "countyName": "Oławski"
        },
        {
            "countyName": "Opatowski"
        },
        {
            "countyName": "Opoczyński"
        },
        {
            "countyName": "Opole"
        },
        {
            "countyName": "Opolski"
        },
        {
            "countyName": "Opolski"
        },
        {
            "countyName": "Ostrołęcki"
        },
        {
            "countyName": "Ostrołęka"
        },
        {
            "countyName": "Ostrowiecki"
        },
        {
            "countyName": "Ostrowski"
        },
        {
            "countyName": "Ostrowski"
        },
        {
            "countyName": "Ostródzki"
        },
        {
            "countyName": "Ostrzeszowski"
        },
        {
            "countyName": "Oświęcimski"
        },
        {
            "countyName": "Otwocki"
        },
        {
            "countyName": "Pabianicki"
        },
        {
            "countyName": "Pajęczański"
        },
        {
            "countyName": "Parczewski"
        },
        {
            "countyName": "Piaseczyński"
        },
        {
            "countyName": "Piekary Śląskie"
        },
        {
            "countyName": "Pilski"
        },
        {
            "countyName": "Pińczowski"
        },
        {
            "countyName": "Piotrkowski"
        },
        {
            "countyName": "Piotrków Trybunalski"
        },
        {
            "countyName": "Piski"
        },
        {
            "countyName": "Pleszewski"
        },
        {
            "countyName": "Płock"
        },
        {
            "countyName": "Płocki"
        },
        {
            "countyName": "Płoński"
        },
        {
            "countyName": "Poddębicki"
        },
        {
            "countyName": "Policki"
        },
        {
            "countyName": "Polkowicki"
        },
        {
            "countyName": "Poznań"
        },
        {
            "countyName": "Poznański"
        },
        {
            "countyName": "Proszowicki"
        },
        {
            "countyName": "Prudnicki"
        },
        {
            "countyName": "Pruszkowski"
        },
        {
            "countyName": "Przasnyski"
        },
        {
            "countyName": "Przemyski"
        },
        {
            "countyName": "Przemyśl"
        },
        {
            "countyName": "Przeworski"
        },
        {
            "countyName": "Przysuski"
        },
        {
            "countyName": "Pszczyński"
        },
        {
            "countyName": "Pucki"
        },
        {
            "countyName": "Puławski"
        },
        {
            "countyName": "Pułtuski"
        },
        {
            "countyName": "Pyrzycki"
        },
        {
            "countyName": "Raciborski"
        },
        {
            "countyName": "Radom"
        },
        {
            "countyName": "Radomski"
        },
        {
            "countyName": "Radomszczański"
        },
        {
            "countyName": "Radziejowski"
        },
        {
            "countyName": "Radzyński"
        },
        {
            "countyName": "Rawicki"
        },
        {
            "countyName": "Rawski"
        },
        {
            "countyName": "Ropczycko-Sędziszowski"
        },
        {
            "countyName": "Ruda Śląska"
        },
        {
            "countyName": "Rybnicki"
        },
        {
            "countyName": "Rybnik"
        },
        {
            "countyName": "Rycki"
        },
        {
            "countyName": "Rypiński"
        },
        {
            "countyName": "Rzeszowski"
        },
        {
            "countyName": "Rzeszów"
        },
        {
            "countyName": "Sandomierski"
        },
        {
            "countyName": "Sanocki"
        },
        {
            "countyName": "Sejneński"
        },
        {
            "countyName": "Sępoleński"
        },
        {
            "countyName": "Siedlce"
        },
        {
            "countyName": "Siedlecki"
        },
        {
            "countyName": "Siemianowice Śląskie"
        },
        {
            "countyName": "Siemiatycki"
        },
        {
            "countyName": "Sieradzki"
        },
        {
            "countyName": "Sierpecki"
        },
        {
            "countyName": "Skarżyski"
        },
        {
            "countyName": "Skierniewice"
        },
        {
            "countyName": "Skierniewicki"
        },
        {
            "countyName": "Sławieński"
        },
        {
            "countyName": "Słubicki"
        },
        {
            "countyName": "Słupecki"
        },
        {
            "countyName": "Słupsk"
        },
        {
            "countyName": "Słupski"
        },
        {
            "countyName": "Sochaczewski"
        },
        {
            "countyName": "Sokołowski"
        },
        {
            "countyName": "Sokólski"
        },
        {
            "countyName": "Sopot"
        },
        {
            "countyName": "Sosnowiec"
        },
        {
            "countyName": "Stalowowolski"
        },
        {
            "countyName": "Starachowicki"
        },
        {
            "countyName": "Stargardzki"
        },
        {
            "countyName": "Starogardzki"
        },
        {
            "countyName": "Staszowski"
        },
        {
            "countyName": "Strzelecki"
        },
        {
            "countyName": "Strzelecko-Drezdenecki"
        },
        {
            "countyName": "Strzeliński"
        },
        {
            "countyName": "Strzyżowski"
        },
        {
            "countyName": "Sulęciński"
        },
        {
            "countyName": "Suski"
        },
        {
            "countyName": "Suwalski"
        },
        {
            "countyName": "Suwałki"
        },
        {
            "countyName": "Szamotulski"
        },
        {
            "countyName": "Szczecin"
        },
        {
            "countyName": "Szczecinecki"
        },
        {
            "countyName": "Szczycieński"
        },
        {
            "countyName": "Sztumski"
        },
        {
            "countyName": "Szydłowiecki"
        },
        {
            "countyName": "Średzki"
        },
        {
            "countyName": "Średzki"
        },
        {
            "countyName": "Śremski"
        },
        {
            "countyName": "Świdnicki"
        },
        {
            "countyName": "Świdnicki"
        },
        {
            "countyName": "Świdwiński"
        },
        {
            "countyName": "Świebodziński"
        },
        {
            "countyName": "Świecki"
        },
        {
            "countyName": "Świętochłowice"
        },
        {
            "countyName": "Świnoujście"
        },
        {
            "countyName": "Tarnobrzeg"
        },
        {
            "countyName": "Tarnobrzeski"
        },
        {
            "countyName": "Tarnogórski"
        },
        {
            "countyName": "Tarnowski"
        },
        {
            "countyName": "Tarnów"
        },
        {
            "countyName": "Tatrzański"
        },
        {
            "countyName": "Tczewski"
        },
        {
            "countyName": "Tomaszowski"
        },
        {
            "countyName": "Tomaszowski"
        },
        {
            "countyName": "Toruń"
        },
        {
            "countyName": "Toruński"
        },
        {
            "countyName": "Trzebnicki"
        },
        {
            "countyName": "Tucholski"
        },
        {
            "countyName": "Turecki"
        },
        {
            "countyName": "Tychy"
        },
        {
            "countyName": "Wadowicki"
        },
        {
            "countyName": "Wałbrzych"
        },
        {
            "countyName": "Wałbrzyski"
        },
        {
            "countyName": "Wałecki"
        },
        {
            "countyName": "Warszawa"
        },
        {
            "countyName": "Warszawski Zachodni"
        },
        {
            "countyName": "Wąbrzeski"
        },
        {
            "countyName": "Wągrowiecki"
        },
        {
            "countyName": "Wejherowski"
        },
        {
            "countyName": "Węgorzewski"
        },
        {
            "countyName": "Węgrowski"
        },
        {
            "countyName": "Wielicki"
        },
        {
            "countyName": "Wieluński"
        },
        {
            "countyName": "Wieruszowski"
        },
        {
            "countyName": "Włocławek"
        },
        {
            "countyName": "Włocławski"
        },
        {
            "countyName": "Włodawski"
        },
        {
            "countyName": "Włoszczowski"
        },
        {
            "countyName": "Wodzisławski"
        },
        {
            "countyName": "Wolsztyński"
        },
        {
            "countyName": "Wołomiński"
        },
        {
            "countyName": "Wołowski"
        },
        {
            "countyName": "Wrocław"
        },
        {
            "countyName": "Wrocławski"
        },
        {
            "countyName": "Wrzesiński"
        },
        {
            "countyName": "Wschowski"
        },
        {
            "countyName": "Wysokomazowiecki"
        },
        {
            "countyName": "Wyszkowski"
        },
        {
            "countyName": "Zabrze"
        },
        {
            "countyName": "Zambrowski"
        },
        {
            "countyName": "Zamojski"
        },
        {
            "countyName": "Zamość"
        },
        {
            "countyName": "Zawierciański"
        },
        {
            "countyName": "Ząbkowicki"
        },
        {
            "countyName": "Zduńskowolski"
        },
        {
            "countyName": "Zgierski"
        },
        {
            "countyName": "Zgorzelecki"
        },
        {
            "countyName": "Zielona Góra"
        },
        {
            "countyName": "Zielonogórski"
        },
        {
            "countyName": "Złotoryjski"
        },
        {
            "countyName": "Złotowski"
        },
        {
            "countyName": "Zwoleński"
        },
        {
            "countyName": "Żagański"
        },
        {
            "countyName": "Żarski"
        },
        {
            "countyName": "Żniński"
        },
        {
            "countyName": "Żory"
        },
        {
            "countyName": "Żuromiński"
        },
        {
            "countyName": "Żyrardowski"
        },
        {
            "countyName": "Żywiecki"
        }
    ]
}
